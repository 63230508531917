import React, { Component, useContext } from 'react';
import { Layout, Row, Col, Tooltip, Button, List, Typography, Card, message, Spin, Statistic, Tag, Modal, Space, Dropdown, Switch, Empty, Checkbox } from "antd";
import axios from 'axios';
import { BiTask } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { MoreOutlined, FilterOutlined, CloseOutlined } from "@ant-design/icons";

//Componentes
import User from "../../../Hooks/Logged";
import { FaShopify } from "react-icons/fa";
import useSearch from '../../../Hooks/useSearch';
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import ClienteSubMenu from '../../Widgets/Var/ClienteSubMenu';
import FloatingButtonActions from "../../Widgets/FloatingButton-actions";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { renderEstatusOrdenPago, renderEstatusOrdenEnvio } from "../../Utils";

//Modales
import ModalOrdenes from './ModalOrdenes';
import DrawerBitacora from './DrawerBitacora';
import ModalFulfilment from './ModalFulfilment';
import ModalCancelarOrden from './ModalCancelarOrden';
import ModalTransacciones from './ModalTransacciones';
import ModalOrdenesDetalles from './ModalOrdenesDetalles';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros';

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;
const moment = require('moment')

/**
 *
 *
 * @export
 * @class Ordenes
 * @extends {Component}
 * @description Vista del listado de Ordenes de Shopify
 */
class Ordenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cuenta: {},
            ordenes: {
                data: [],
                limit: 50,
                page: 1,
                total: 0,
                filters: [],
                reglas: false
            },
            seleccionadas: {},

            pedidos:{
                tipo: "normal",
                label: "Ver borradores",
            }
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes({reglas: this.props.asesor})
    }

    componentDidUpdate(prevProps){
        if(this.props.search !== prevProps.search){
            this.getOrdenes({page: 1, search: this.props.search})
        }
    }

    /**
    * @memberof Ordenes
    * @description Obtiene una lista de ordenes provenientes directamente del api de shopify
    */
    getOrdenes = ({
        limit = this.state.ordenes.limit,
        page = this.state.ordenes.page,
        reglas = this.state.ordenes?.reglas,
        filters = this.state.ordenes.filters,
        search = this.props.search,
    } = this.state.ordenes) => {

        this.setState({ loading: true })

        let productos_ids = (filters?.filter(element => element.name === "producto_id"))?.map(e => e._id)
        let variantes_ids = (filters?.filter(element => element.name === "variante_id"))?.map(e => e._id)

        axios.get('/ordenes', {
            params: {
                limit,
                page,
                reglas,
                filters: filters.filter(item => item.name !== "producto_id" && item.name !== "variante_id"),
                search,
                productos_ids,
                variantes_ids,
                tipo: this.state.pedidos.tipo,
                orden_detalles_simple: true,
            }
        }).then(({ data }) => {
            this.setState({
                ordenes: {
                    ...data,
                    reglas: reglas,
                    filters:[
                        ...filters
                    ]
                }
            })

        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los pedidos')
        }).finally(() => this.setState({ loading: false }))

    }

    /**
    *
    * @memberof Ordenes
    * 
    * @method clasificarTransacciones
    * @description Abre el modal si se seleccionaron ordenes
    */
    clasificarTransacciones = () => {
        const selecionadasLenght = Object.keys(this.state.seleccionadas).length
        if (selecionadasLenght === 0)
            return Modal.error({
                title: "Debe seleccionar algunos pedidos",
                content: "Para poder agregar pedidos "
            })
        this.setState({ modalClasificarVisible: true })
    }


    /**
    *
    * @memberof Ordenes
    * 
    * @method renderMenuItems
    * @description renderiza los elementos del menu de cada pedido
    */
    renderMenuItems = (orden) => {

        let menu_items = [
            {
                key: '1',
                label: <Text>Ver Detalle</Text>,
                onClick: () => this.setState({ modal_visible_detalle: true, orden_id: orden._id })
            },
            {
                key: '2',
                label: <Text>Ver Transacciones</Text>,
                onClick: () => this.setState({ modal_transacciones: true, orden_id: orden._id })
            },
            {
                key: '3',
                label: <Text>Ver PDF</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/pdf')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')
                }
            },
            {
                key: '6',
                label: <Text>Ver PDF de Empaquetado</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/packing')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')

                    this.getOrdenes();
                }
            },
        ]
        if(!orden.shopify_cancelled_at){
            menu_items.push({
                    key: '4',
                    label: <Text>Editar</Text>,
                    disabled: !this.props.canEdit,

                    onClick: () => this.setState({ modal_visible: true, orden_id: orden._id })
            })
        }
        

        if (orden.shopify_draft_id && !orden.shopify_id) {
            menu_items.unshift({
                key: "0",
                label: <Text>Aprobar Draft</Text>,
                onClick: () => this.aprobarOrden(orden._id)
            })
        }

        if(orden.bitacora_log){
            menu_items.push({
                key: "7",
                label: <Text>Ver Bitcora</Text>,
                onClick: () => this.setState({ drawer_bitacora: true , orden_id: orden._id})
            })
        }

        if(orden.shopify_fulfillment_status !== "fulfilled"){
            menu_items.push({
                key: "9",
                label: <Text>Preparar envio</Text>,
                onClick: () => this.setState({ modal_fulfillment: true , orden_id: orden._id})
            })
        }

        if(!orden.shopify_cancelled_at){
            menu_items.push(
                {
                    key: '8',
                    label: <Text>Cancelar Pedido</Text>,
                    disabled: !this.props.canDelete,
                    onClick: () => {
                        confirm({
                            title: "¿Deseas CANCELAR este pedido?",
                            cancelText: "Cerrar",
                            okText: "SI",
                            onOk: () => {
                                this.setState({ loading: true })
                                axios.post('/ordenes/cancelar',{
                                    orden_id: orden._id
                                }).then((response) => {
                                    message.success('Pedido Cancelado')
                                    this.getOrdenes();
                                    this.setState({ modal_cancelar: true , orden_id: orden._id})
                                }).catch((error) => {
                                    message.error('Pedido NO Cancelado')
                                }).finally(() => this.setState({ loading: false }))
                            },
                        });
                    }
                }
            )
        }

        if(orden.shopify_cancelled_at){
            menu_items.push({
                key: "7",
                label: <Text>Regresar Productos</Text>,
                onClick: () => this.setState({ modal_cancelar: true , orden_id: orden._id})
            })
        }

        menu_items.push({
                key: '5',
                label: <Text>Eliminar</Text>,
                disabled: !this.props.canDelete,
                onClick: () => {
                    confirm({
                        title: "¿Deseas eliminar este pedido?",
                        cancelText: "Cancelar",
                        okText: "SI",
                        onOk: () => {
                            this.setState({ loading: true })
                            axios.delete('/ordenes/' + orden._id).then((response) => {
                                message.success('Pedido Eliminada')
                                this.getOrdenes()
                            }).catch((error) => {
                                message.error('Pedido NO Eliminado')
                            }).finally(() => this.setState({ loading: false }))
                        },
                    });
                }
            }
        )

        return menu_items
    }

    /**
    *
    * @memberof Ordenes
    * 
    * @method aprobarOrden
    * @description Aprueba la orden y pasa de draft a pedido normal
    */
    aprobarOrden = (orden_id) => {
        this.setState({ loading: true })
        axios.post('/ordenes/aprobar', {
            orden_id
        })
            .then(response => {
                message.success("Orden Aprobada")
                this.getOrdenes()
            })
            .catch(error => {
                console.log("error", error);
                message.error("Error al aprobar la orden")
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    *
    * @memberof ClientesDetalles
    * 
    * @method getPDFVariantes
    * @description Generamos el PDF del estado de cuenta del cliente
    */
    getPDFVariantes = () => {
        let url = new URL(axios.defaults.baseURL + '/reportes/variantes')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))

        if(this.state?.ordenes?.filters?.length > 0){
            let filters = JSON.parse(JSON.stringify(this.state?.ordenes?.filters));
            url.searchParams.append('filters', JSON.stringify(filters?.filter(item => item.name !== "producto_id" && item.name !== "variante_id")))
            let productos_ids = (filters?.filter(element => element.name === "producto_id"))?.map(e => e._id)
            if(productos_ids) url.searchParams.append('productos_ids', JSON.stringify(productos_ids))
            let variantes_ids = (filters?.filter(element => element.name === "variante_id"))?.map(e => e._id)
            if(variantes_ids) url.searchParams.append('variantes_ids', JSON.stringify(variantes_ids))
        }

        if(this.props.search) url.searchParams.append('search', this.props.search)

        window.open(url, '_blank')
    }

    /**
    *
    * @memberof ClientesDetalles
    * 
    * @method getPDFPedidos
    * @description Generamos el PDF del estado de cuenta del cliente
    */
    getPDFPedidos = () => {
        let url = new URL(axios.defaults.baseURL + '/reportes/pedidos')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))

        if(this.state?.ordenes?.filters?.length > 0){
            let filters = JSON.parse(JSON.stringify(this.state?.ordenes?.filters));
            url.searchParams.append('filters', JSON.stringify(filters?.filter(item => item.name !== "producto_id" && item.name !== "variante_id")))
            let productos_ids = (filters?.filter(element => element.name === "producto_id"))?.map(e => e._id)
            if(productos_ids) url.searchParams.append('productos_ids', JSON.stringify(productos_ids))
            let variantes_ids = (filters?.filter(element => element.name === "variante_id"))?.map(e => e._id)
            if(variantes_ids) url.searchParams.append('variantes_ids', JSON.stringify(variantes_ids))
        }

        if(this.props.search) url.searchParams.append('search', this.props.search)

        window.open(url, '_blank')
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Ordenes
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters = [] } = this.state.ordenes) => {

        return filters.map(filtro => {
            return <Tag
                key={filtro._id}
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}><CloseOutlined /></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.range?.join(' - ')}
                {filtro?.label || filtro?.nombre || filtro?.razon_social || filtro?.email || filtro?.folio}
            </Tag>
        });
    }

    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        try {
            let { filters } = this.state.ordenes
            let { _id, name } = filtro

            filters = filters.filter(f => {
                if (f.name === name) {
                    if (f.name === 'fecha') return false
                    return (f._id !== _id)
                }
                return true
            })

            this.setState({ ordenes: { ...this.state.ordenes, filters } }, () => this.getOrdenes())
        } catch (error) {
            console.log(error)
            this.setState({ visibleFilters: true })
        }
    }

    /**
    *
    * @memberof Ordenes
    * 
    * @method changeList
    * @description Filtra las ordenes consfimadas o los borradores
    */
    changeList = () => {
        let { pedidos } = this.state;

        let obj = {}
        if(pedidos.tipo === "normal"){
            obj = {
                pedidos:{
                    tipo: "borradores",
                    label: "Ver Pedidos"
                }
            }
        }else{
            obj = {
                pedidos:{
                    tipo: "normal",
                    label: "Ver Borradores"
                }
            }
        }

        this.setState({...obj},()=>this.getOrdenes())
    }

    render() {


        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title={`Pedidos`}
                        extra={<>
                            <Button onClick={() => this.getPDFVariantes()}>Variantes</Button>
                            <Button onClick={() => this.getPDFPedidos()}>Pedidos</Button>
                            <Button onClick={() => this.changeList()} loading={this.state.loading}>{this.state.pedidos.label}</Button>
                            <Button
                                onClick={() => this.setState({ visibleFilters: true })}
                                className="button-plus"
                                icon={<FilterOutlined style={{ color: '#000', fontWeight: 600 }} />} 
                            />
                            {this.props.asesor ?
                                <Tooltip title="Visualiza pedidos de acuerdo a las reglas aplicadas al asesor.">
                                    <Switch 
                                        title="Aplicar mis reglas" 
                                        checkedChildren="Filtrado" 
                                        unCheckedChildren="Filtrar Pedidos" 
                                        onChange={(checked) => this.getOrdenes({ reglas: checked })} 
                                        defaultChecked={this.props.asesor}  
                                    >
                                        </Switch>
                                </Tooltip>
                                : null}
                        </>}
                    />

                    <Content className="admin-content content-bg pd-1">
                        <Row>
                            <Col>
                                {/*this.renderFiltros()*/}
                            </Col>
                        </Row>
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.ordenes.data}
                            pagination={{
                                current: this.state.ordenes.page,
                                pageSize: this.state.ordenes.limit,
                                total: this.state.ordenes.total,
                                position: 'bottom',
                                className: "flex-left",
                                pageSizeOptions: [10,20,50,100],
                                showSizeChanger: true,
                                onChange: (page, limit) => this.getOrdenes({ page, limit })
                            }}
                            locale={{ emptyText:  <Empty/> }}
                            header={<Row className="header-list width-100 pl-1 pr-1">
                                <Col xs={1} className=" center">

                                </Col>
                                <Col xs={12} md={6} lg={6} xl={3} className=" center">
                                    <Text strong>Folio</Text>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={3} className="center">
                                    <Text strong>Artículos</Text>
                                </Col>
                                <Col xs={12} md={6} lg={5} xl={4} className="center">
                                    <Text strong>Cliente</Text>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={3} className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={3} className="center">
                                    <Text strong>Estatus Pago</Text>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={3} className="center">
                                    <Text strong>Estatus Pedido</Text>
                                </Col>
                                <Col xs={23} md={11} lg={6} xl={3} className="center">
                                    <Text strong>Monto</Text>
                                </Col>
                                <Col xs={1} md={1} lg={1} xl={1} className="center">
                                    <Text strong></Text>
                                </Col>
                            </Row>
                            }
                            renderItem={item => {
                                const { total, cliente_id, fecha, _id, folio, shopify_cliente, total_productos, asesor } = item;
                                return <List.Item className="component-list-item">
                                    <Tooltip trigger={(item.orden_id) ? ["hover"] : []} title=" Este pedido se clasificó previamente" placement="topLeft">
                                        <Card
                                            style={{ ...(item.shopify_cancelled_at) ? { background: "rgba(0,0,0,0.1)" } : {} }}
                                            className="card-list"
                                        >
                                            
                                            <Row className="width-100">
                                                <Col xs={1} className="center">
                                                    <Checkbox className="radio-purple"
                                                        //disabled={(item.shopify_cancelled_at)}
                                                        checked={this.state.seleccionadas[_id]}
                                                        onChange={value => {
                                                            this.setState(state => {
                                                                if (value.target.checked === true)
                                                                    state.seleccionadas[_id] = item
                                                                else
                                                                    delete state.seleccionadas[_id]
                                                                return state;
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6} lg={6} xl={3} className="center hover" onClick={() => this.setState({ modal_visible_detalle: true, orden_id: item._id })}>
                                                    <Space>
                                                        {item.bitacora_log ? <BiTask style={{ color: 'orange' }} /> : null}
                                                        { !item.shopify_id && !item.shopify_draft_id ? <FaShopify style={{color: "red"}}/> : null}
                                                        <Text delete={item.shopify_cancelled_at}>{item.shopify_folio ?? folio}</Text>
                                                        {item.shopify_draft_id && !item.shopify_id ? <Tag color="magenta">Draft</Tag> : null}
                                                    </Space>
                                                </Col>
                                                <Col xs={12} md={6} lg={6} xl={3} className="center">
                                                    <Text delete={item.shopify_cancelled_at}>{total_productos ?? 0} artículos</Text>
                                                </Col>
                                                <Col xs={12} md={5} lg={5} xl={4} className="center">
                                                    <ClienteSubMenu
                                                        delete_order={item.shopify_cancelled_at}
                                                        cliente={cliente_id} 
                                                        shopify_cliente={shopify_cliente}
                                                        asesor={asesor}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6} lg={6} xl={3} className="center">
                                                    <Text strong delete={item.shopify_cancelled_at}>{moment(fecha).format('DD-MM-YYYY HH:mm')}</Text>
                                                </Col>
                                                <Col xs={12} md={6} lg={6} xl={3} className="center">
                                                    {renderEstatusOrdenPago(item.shopify_financial_status)}
                                                </Col>
                                                <Col xs={12} md={6} lg={6} xl={3} className="center">
                                                    {renderEstatusOrdenEnvio(item.shopify_fulfillment_status)}
                                                </Col>
                                                <Col xs={23} md={11} lg={11} xl={3} className="center">
                                                    <Statistic
                                                        delete={item.shopify_cancelled_at}
                                                        value={total}
                                                        valueStyle={{
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            color: item.shopify_financial_status === "paid" ? '#2c9d37' : "#ecc602"
                                                        }}
                                                        prefix={'$'} suffix={"MXN"} />
                                                </Col>
                                                <Col xs={1} md={1} lg={1} xl={1} className="center">
                                                    <Space>
                                                        <Dropdown
                                                            placement="bottomRight"
                                                            menu={{
                                                                items: this.renderMenuItems(item)
                                                            }}
                                                            trigger={['click']}
                                                        >
                                                            <Button
                                                                type="ghost"
                                                                onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}
                                                                icon={<MoreOutlined style={{ color: "black" }} />}
                                                            />
                                                        </Dropdown>

                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Tooltip>
                                </List.Item>
                            }}
                        />
                        {this.props.canCreate && <FloatingButton title="Clasificar" onClick={() => this.setState({ modal_visible: true })} />}
                    </Content>
                </Spin>

                <ModalOrdenes
                    open={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false, orden_id: undefined })
                        if (flag === true)
                            this.getOrdenes();
                    }}
                    orden_id={this.state.orden_id}
                />

                <ModalOrdenesDetalles
                    open={this.state.modal_visible_detalle}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                    reload={()=>this.getOrdenes()}
                    user={this.props.user}
                />

                <ModalTransacciones
                    open={this.state.modal_transacciones}
                    onClose={(flag) => {
                        this.setState({ modal_transacciones: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />

                <ModalCancelarOrden
                    open={this.state.modal_cancelar}
                    onClose={(flag) => {
                        this.setState({ modal_cancelar: false, orden_id: undefined, ordenes_ids: undefined })
                    }}
                    orden_id={this.state.orden_id}
                    ordenes_ids={this.state.ordenes_ids}
                />

                <DrawerBitacora
                    open={this.state.drawer_bitacora}
                    onClose={() => {
                        this.setState({ drawer_bitacora: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />

                <ModalFulfilment
                    open={this.state.modal_fulfillment}
                    onClose={(flag) => {
                        this.setState({ modal_fulfillment: false, orden_id: undefined })
                        if(flag === true)
                            this.getOrdenes()
                    }}
                    orden_id={this.state.orden_id}

                />

                <FloatingButtonActions
                    visible={Object.keys(this.state.seleccionadas).length}
                    items={[
                        {
                            label: "Imprimir",
                            onClick: () => {
                                let url = new URL(axios.defaults.baseURL + '/ordenes/packings')
                                url.searchParams.append('ordenes_id', JSON.stringify(Object.keys(this.state.seleccionadas)))
                                url.searchParams.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
                                url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                                window.open(url, '_blank')
                                this.getOrdenes()
                                this.setState({seleccionadas: {}})
                            }
                        },{
                            label: "Pagado",
                            onClick: () => {
                                confirm({
                                    title: "¿Deseas marcar como PAGADOS estos pedido?",
                                    content: "Se creara una transacción por cada orden para completar el pago pendiente",
                                    cancelText: "Cerrar",
                                    okText: "SI",
                                    onOk: () => {
                                        this.setState({ loading: true })
                                        axios.post('/ordenes/pagar',{
                                            ordenes_id: Object.keys(this.state.seleccionadas)
                                        }).then((response) => {
                                            message.success('Pedidos Pagados')
                                            this.getOrdenes()
                                            this.setState({seleccionadas: {}})
                                        }).catch((error) => {
                                            message.error('Pedidos NO Pagados')
                                        }).finally(() => this.setState({ loading: false }))
                                    },
                                });
                            }
                        },{
                            label: "Cancelar",
                            onClick: () => {
                                confirm({
                                    title: "¿Deseas CANCELAR estos pedidos?",
                                    cancelText: "Cerrar",
                                    okText: "SI",
                                    onOk: () => {
                                        this.setState({ loading: true })
                                        axios.post('/ordenes/cancelar',{
                                            ordenes_id: Object.keys(this.state.seleccionadas)
                                        }).then((response) => {
                                            message.success('Pedidos Cancelados')
                                            this.getOrdenes()
                                            this.setState({ modal_cancelar: true, ordenes_ids: Object.keys(this.state.seleccionadas), seleccionadas: {} })
                                            //this.setState({seleccionadas: {}})
                                        }).catch((error) => {
                                            message.error('Pedidos NO Cancelados')
                                        }).finally(() => this.setState({ loading: false }))
                                    },
                                });
                            }
                        }
                    ]}
                />

                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Pedidos"
                    updateFilters={filters => this.getOrdenes({
                        page: 1,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    fechas={true}
                    clientes={true}
                    productos={true}
                    shopify_financial_status={[
                        {
                            _id: "pending",
                            label: "Pago Pendiente"
                        },{
                            _id: "partially_paid",
                            label: "Pago Parcial"
                        },{
                            _id: "paid",
                            label: "Pagado"
                        },{
                            _id: "voided",
                            label: "Pago Anulado"
                        },{
                            _id: "refunded",
                            label: "Rembolsado"
                        }
                    ]}
                    shopify_fulfillment_status={
                        [
                            {
                                _id: "no_fulfilled",
                                label: "No Preparado"
                            },{
                                _id: "fulfilled",
                                label: "Envio Preparado"
                            }
                        ]
                    }
                />

            </>
        )
    }
}


const Vista = function (params) {

    const user = useContext(User);

    const { search, setShow } = useSearch()

    const asesor = user.is_asesor;

    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ["ordenes", "edit"],
        canDelete: ["ordenes", "delete"],
        canCreate: ["ordenes", "create"],
    });

    return <Ordenes {...params} params={useParams()} navigate={useNavigate()} {...permisos} asesor={asesor} search={search} setShowSearch={setShow} user={user}/>
}

export default Vista