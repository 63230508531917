import React, { Component, useContext, forwardRef } from 'react';
import { Row, Col, Tooltip, Typography, Dropdown, Modal, message, Statistic, Space, Collapse, Tag, Pagination, Button, Divider, Spin, Card } from "antd";
import axios from 'axios';
import dayjs from "dayjs";
import { AiFillDollarCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { FaShopify, FaTruck } from "react-icons/fa";
import { MoreOutlined } from '@ant-design/icons';

//Componentes
import User from "../../../Hooks/Logged";
import { renderEstatusOrdenEnvio, renderEstatusOrdenPago } from "../../Utils";
import usePermissions from "../../../Hooks/usePermissions";
import ClienteSubMenu from '../../Widgets/Var/ClienteSubMenu';

//Modales
import ModalOrdenesDetalles from '../Ordenes/ModalOrdenesDetalles'
import DrawerBitacora from '../Ordenes/DrawerBitacora'
import ModalOrdenes from '../Ordenes/ModalOrdenes'
import ModalTransacciones from '../Ordenes/ModalTransacciones'



const { confirm } = Modal;
const { Text } = Typography;

/**
 *
 *
 * @export
 * @class Ordenes
 * @extends {Component}
 * @description Vista del listado de Ordenes de Shopify
 */
class OrdenesCollapse extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            ordenes: {
                data: [],
                limit: 10,
                page: 1,
                total: 0
            },

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
    }



    /**
    * @memberof Ordenes
    * @description Obtiene una lista de ordenes
    */
    getOrdenes = ({
        limit = this.state.ordenes.limit,
        page = this.state.ordenes.page,
        agrupar = this.props.agrupar,
        cliente_id = this.props.cliente_id,
        rango_fechas = this.props.fechas
    } = this.state.ordenes) => {

        console.log('props',this.props)
        this.setState({ loading: true })
        axios.get('/ordenes', {
            params: {
                limit,
                page,
                orden_detalles: true,
                agrupar,
                cliente_id,
                rango_fechas,
                tipo: "normal",
                ...this.props.params,
            }
        }).then(({ data }) => {
            console.log('data', data)
            this.setState({
                ordenes: data
            })

        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los pedidos')
        }).finally(() => this.setState({ loading: false }))

    }

    /**
       *
       * @memberof Ordenes
       * 
       * @method renderMenuItems
       * @description renderiza los elementos del menu de cada pedido
       */
    renderMenuItems = (orden) => {

        let menu_items = [
            {
                key: '1',
                label: <Text>Ver Detalle</Text>,
                onClick: () => this.setState({ modal_visible_detalle: true, orden_id: orden._id })
            },
            {
                key: '2',
                label: <Text>Ver Transacciones</Text>,
                onClick: () => this.setState({ modal_transacciones: true, orden_id: orden._id, orden: orden })
            },
            {
                key: '3',
                label: <Text>Ver PDF</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/pdf')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')
                }
            },
            {
                key: '6',
                label: <Text>Ver PDF de Empaquetado</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/packing')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')

                    this.getOrdenes()
                }
            },
            {
                key: '4',
                label: <Text>Editar</Text>,
                disabled: !this.props.canEdit,

                onClick: () => this.setState({ modal_visible: true, orden_id: orden._id })
            },
            {
                key: '5',
                label: <Text>Eliminar</Text>,
                disabled: !this.props.canDelete,
                onClick: () => {
                    confirm({
                        title: "¿Deseas eliminar este pedido?",
                        cancelText: "Cancelar",
                        okText: "SI",
                        onOk: () => {
                            this.setState({ loading: true })
                            axios.delete('/ordenes/' + orden._id).then((response) => {
                                message.success('Pedido Eliminada')
                                this.getOrdenes()
                            }).catch((error) => {
                                message.error('Pedido NO Eliminado')
                            }).finally(() => this.setState({ loading: false }))
                        },
                    });
                }
            }


        ]

        if (orden.shopify_draft_id && !orden.shopify_id) {
            menu_items.unshift({
                key: "0",
                label: <Text>Aprobar Draft</Text>,
                onClick: () => this.aprobarOrden(orden._id)
            })
        }

        if (orden.bitacora_log) {
            menu_items.push({
                key: "7",
                label: <Text>Ver Bitcora</Text>,
                onClick: () => this.setState({ drawer_bitacora: true, orden_id: orden._id })
            })
        }

        return menu_items
    }


    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Row className="header-list width-100 pl-2 pr-1 mb-1 mt-1">
                    <Col xs={12} md={3} className="center">
                        <Text strong>Pedido</Text>
                    </Col>
                    <Col xs={12} md={3} className="center">
                        <Text strong>Artículos</Text>
                    </Col>
                    <Col xs={12} md={3} className="center">
                        <Text strong>Cliente</Text>
                    </Col>
                    <Col xs={12} md={4} className="center">
                        <Text strong>Monto Total</Text>
                    </Col>
                    <Col xs={12} md={3} className="center">
                        <Text strong>Estatus Pago</Text>
                    </Col>
                    <Col xs={12} md={3} className="center">
                        <Text strong>Estatus Pedido</Text>
                    </Col>
                    <Col xs={12} md={2} className="center">
                        <Text strong>Fecha</Text>
                    </Col>
                </Row>
                <Collapse
                    className="collapse-vapemate"
                    items={this.state.ordenes?.data?.map((item, index) => ({
                        key: index,
                        label: <>
                            <Row className="width-100">
                                <Col xs={24} className="center">
                                    <Row gutter={[8, 8]} className="width-100">
                                        <Col xs={12} md={3} className="center hover"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                this.setState({ modal_visible_detalle: true, orden_id: item._id })
                                            }}
                                            style={{ zIndex: 1000 }}
                                        >
                                            <Space >
                                                {!item.shopify_id && !item.shopify_draft_id ? <FaShopify style={{ color: "red" }} /> : null}
                                                <Text>{item.shopify_folio ?? item.folio}</Text>
                                                {item.shopify_draft_id && !item.shopify_id ? <Tag color="magenta">Draft</Tag> : null}
                                            </Space>
                                        </Col>
                                        <Col xs={12} md={3} className="flex-column">
                                            <Text strong ellipsis>{item.total_productos ?? 0} Artículos</Text>
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            <ClienteSubMenu
                                                cliente={item.cliente_id}
                                                shopify_cliente={item.shopify_cliente}
                                            /> </Col>
                                        <Col xs={12} md={4} className="center">
                                            <Statistic
                                                value={item.total}
                                                prefix="$"
                                                valueStyle={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    color: item.shopify_financial_status == "paid" ? '#2c9d37' : "#ecc602"
                                                }}
                                                precision={2}
                                            />
                                        </Col>
                                        <Col xs={12} md={3} className="flex-column">
                                            {renderEstatusOrdenPago(item.shopify_financial_status)}
                                        </Col>
                                        <Col xs={12} md={3} className="flex-column">
                                            {renderEstatusOrdenEnvio(item.shopify_fulfillment_status)}
                                        </Col>
                                        <Col xs={12} md={2} className="center">
                                            <Text>{dayjs(item.fecha).format("DD/MM/YY")}</Text>
                                        </Col>
                                        <Col xs={12} md={1} className="center">
                                            <FaTruck style={{ fontSize: "24px" }} onClick={(e) => {
                                                e.stopPropagation()
                                                this.setState({ modal_visible_detalle: true, orden_id: item._id })
                                            }} />
                                        </Col>
                                        <Col xs={12} md={1} className="center">
                                            <Tooltip
                                                placement="topRight"
                                                title={item.credito ? "Se utilizó crédito" : "No se utilizó crédito"}
                                            >
                                                <AiFillDollarCircle
                                                    style={{
                                                        color: item.credito ? "#ecc602" : "gray",
                                                        fontSize: "24px"
                                                    }}
                                                />
                                            </Tooltip>
                                        </Col>
                                        <Col xs={12} md={1}>
                                            <Dropdown
                                                placement="bottomRight"
                                                menu={{
                                                    items: this.renderMenuItems(item)
                                                }}
                                            >
                                                <Button
                                                    type="ghost"
                                                    onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}
                                                    icon={<MoreOutlined style={{ color: "black" }} />}
                                                />
                                            </Dropdown></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>,
                        children: [
                            <Row className="width-100 mb-1">
                                <Col xs={24}>
                                    <Row gutter={[8, 8]}>
                                        <Col xs={12} md={5} className="flex-left-column">
                                            <Text ellipsis strong>PRODUCTO</Text>
                                        </Col>
                                        <Col xs={12} md={5} className="center">
                                            <Text ellipsis>PRECIO</Text>
                                        </Col>
                                        <Col xs={12} md={5} className="center">
                                            <Text ellipsis>DESCUENTO</Text>
                                        </Col>
                                        <Col xs={12} md={5} className="center">
                                            <Text ellipsis>CANTIDAD</Text>
                                        </Col>
                                        <Col xs={12} md={4} className="center">
                                            <Text ellipsis>TOTAL</Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>,
                            <Divider />,
                            ...item.ordenes_productos?.map(orden_producto => <Row className="width-100 mb-1">
                                <Col xs={24}>
                                    <Row gutter={[8, 8]}>
                                        <Col xs={12} md={5} className="flex-left-column">
                                            <Text ellipsis strong>{orden_producto?.producto_id?.modelo ?? "--"}</Text>
                                            <Text ellipsis>{orden_producto?.variante_id?.sabor ?? "--"}</Text>
                                        </Col>
                                        <Col xs={12} md={5} className="center">
                                            <Text ellipsis>$ {orden_producto.precio?.toMoney(true) ?? '-'} MXN</Text>
                                        </Col>
                                        <Col xs={12} md={5} className="center">
                                            <Text ellipsis>$ {(orden_producto.descuento_total ?? orden_producto.descuento)?.toMoney(true) ?? '0'} MXN</Text>
                                        </Col>
                                        <Col xs={12} md={5} className="center">
                                            <Text ellipsis>{orden_producto.cantidad?.toMoney(true, 0) ?? 0}</Text>
                                        </Col>
                                        <Col xs={12} md={4} className="center">
                                            <Text ellipsis>$ {orden_producto.total?.toMoney(true, 2) ?? 0} MXN</Text>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>)
                        ]
                    }))}
                />
                <Pagination
                    className="mt-1"
                    showSizeChanger
                    current={this.state.ordenes?.page}
                    total={this.state.ordenes?.total}
                    pageSize={this.state.ordenes?.limit}
                    onChange={(page, limit) => this.getOrdenes({ page, limit })}
                />

                <ModalOrdenes
                    open={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false, orden_id: undefined })
                        if (flag === true)
                            this.getOrdenes();
                    }}
                    orden_id={this.state.orden_id}
                />
                <ModalOrdenesDetalles
                    open={this.state.modal_visible_detalle}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />
                <ModalTransacciones
                    open={this.state.modal_transacciones}
                    onClose={(flag) => {
                        this.setState({ modal_transacciones: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                    orden={this.state.orden}
                />
                <DrawerBitacora
                    open={this.state.drawer_bitacora}
                    onClose={() => {
                        this.setState({ drawer_bitacora: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />
            </Spin >
        )
    }
}


const OrdenesCollapseList = forwardRef((props, ref) => <OrdenesCollapse
    {...props}
    ref={ref}
    permisos={usePermissions(useContext(User)?.rol_id?.permisos, {
        canEdit: ["ordenes", "edit"],
        candDelete: ["ordenes", "delete"],
        canCreate: ["ordenes", "create"],
    })}
    user={useContext(User)}
    navigate={useNavigate()}
/>)

export default OrdenesCollapseList

